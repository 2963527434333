import {
  Navbar as NextNavbar,
  NavbarBrand,
  NavbarContent,
} from "@nextui-org/react";
import UserMenu from "~/components/user-menu";
import { Link } from "@remix-run/react";
import LocationPicker from "./location-picker";
import { LuChevronDown } from "react-icons/lu";

type NavbarProps = {
  hideLogo?: boolean;
};

export default function Navbar({ hideLogo = false }: NavbarProps) {
  return (
    <NextNavbar
      isBlurred={false}
      maxWidth="full"
      classNames={{
        wrapper: "px-4 sm:px-6",
      }}
      position="static"
    >
      <NavbarBrand>
        {!hideLogo && (
          <Link
            title="tribe"
            className="text-primary font-black text-2xl md:text-3xl"
            to="/"
          >
            tribe<span className="!text-base !md:text-lg">.best</span>
          </Link>
        )}
      </NavbarBrand>

      <NavbarContent justify="end">
        <LocationPicker
          buttonProps={{
            radius: "full",
            variant: "bordered",
            size: "sm",
            className: "max-w-[140px] px-3",
            endContent: <LuChevronDown className="w-3 h-3 min-w-3 -ml-1" />,
            labelClassName: "text-ellipsis overflow-hidden whitespace-nowrap",
          }}
        />
        <UserMenu />
      </NavbarContent>
    </NextNavbar>
  );
}
